import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Foto from '../../assets/skilling.png';
import Foto2 from '../../assets/infinox.png';
import { Link } from 'react-router-dom';

// Definindo os links de acordo com o idioma
const linksPorIdioma = {
  pt: "/infinox-pt",
  en: "/infinox-en",
  es: "/infinox-es",
  fr: "/infinox-fr"
};

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
    filter: brightness(0.8);
  } 
  `;

 const ControleRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
 `; 

const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(150px);
  transition: opacity 1s ease, transform 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;

const Title = styled.h1`
  color: white;

  span{
    color: #FFC222;
  }
`;

const Card = styled.div`
  width: 350px;
  background: #212121;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  margin-top: 2rem;
  margin-left: 1rem;
`;

const Imagem = styled.img`
  margin: 1rem;
`;

const TitleCard = styled.h2`
  color: white;
`;

const SubCard = styled.p`
  color: #c3c3c3;
  text-align: center;
`;


const Botao = styled.button`
  margin-top: 0.2rem;
  width: 244px;
  height: 50px;
  background: #FFC222;
  cursor: pointer;
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
  border-radius: 20px;
  border: none;
  transition: 500ms;
  animation: pulse2 500ms infinite alternate;

  &:hover{
    filter: brightness(0.8);
  }

  
  @keyframes pulse2 {
    from {
      opacity: 0.8;
      transform: scale(1);
    }
    to {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;



function Site() {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.fade-in-download1');
      const elementPosition = element.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;

      if (elementPosition < screenHeight) {
        element.classList.add('visible');
      } else {
        element.classList.remove('visible');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <Container>
      <FadeIn className="fade-in-download1">
        <Title>{t('card.title.work')} <span>.</span></Title>
        <ControleRow>
        <Card>
            <Imagem src={Foto}/>  
            <TitleCard>Skilling </TitleCard>
            <SubCard>{t('site.sub1')}</SubCard>
            <StyledLink to="/abertura-skilling">
               <Botao>{t('card.button')}</Botao>
            </StyledLink>
        </Card>

        <Card>
            <Imagem src={Foto2}/>  
            <TitleCard>Infinox </TitleCard>
            <SubCard>{t('site.sub2')}</SubCard>
            <StyledLink to="/infinox">
               <Botao>{t('card.button')}</Botao>
            </StyledLink>
        </Card>
        </ControleRow>
      </FadeIn>
    </Container>
  );
}

export default Site;
