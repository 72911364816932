import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import IconOpen from '../../assets/icon_mais.png';
import IconClose from '../../assets/icon_menos.png';

const FadeIn = styled.div`
  opacity: 0;
  transform: translateY(50px); /* Aumentei a quantidade de translação */
  transition: opacity 1s ease, transform 1s ease; /* Reduzi a duração da transição */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
  background: #151515;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1000px) {
    overflow-x: hidden !important;
  }
`;


const Line = styled.div`
    margin-top: 2rem;
    background: #FFC222;
    width: 2px;
    height: 75px;
`;

const ControlCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;


const Imagem = styled.img`
    width: 26px;
    margin-right: 1.3rem;
`;

const ControllFaq = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 350px;
    }
`;

const ControlTexto = styled.div`
    width: 100%;
    border-radius: 1rem;
    background: #191919;
    padding: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
    cursor: pointer;
`;

const Title = styled.h1`
    color: white;
    margin-bottom: 24px;
    text-align: center;

    span{
    color: #FFC222;
  }
`;

const Text = styled.p`
    color: white;
    font-weight: 600;
    font-size: 1.3rem;
`;

const SubText = styled.p`
    color: #c3c3c3;
    font-weight: 400;
    font-size: 1.3rem;
    margin-bottom: 14px;
`;

function Faq() {
    const { t } = useTranslation();
    const [openCard, setOpenCard] = useState(null);

    const toggleCard = (index) => {
        setOpenCard(openCard === index ? null : index);
    };


    useEffect(() => {
        const handleScroll = () => {
          const element = document.querySelector('.fade-in-faq');
          const elementPosition = element.getBoundingClientRect().top;
          const screenHeight = window.innerHeight;
    
          if (elementPosition < screenHeight) {
            element.classList.add('visible');
          } else {
            element.classList.remove('visible'); // Remove a classe se o elemento não estiver mais visível
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


          const cardsData = [
            {
              icon: IconOpen,
              text: t('faq.title.card.1'),
              subText: t('faq.sub.card.1')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.3'),
            subText: t('faq.sub.card.3')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.4'),
            subText: t('faq.sub.card.4')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.5'),
            subText: t('faq.sub.card.5')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.6'),
            subText: t('faq.sub.card.6')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.7'),
            subText: t('faq.sub.card.7')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.8'),
            subText: t('faq.sub.card.8')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.9'),
            subText: t('faq.sub.card.9')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.10'),
            subText: t('faq.sub.card.10')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.11'),
            subText: t('faq.sub.card.11')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.12'),
            subText: t('faq.sub.card.12')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.13'),
            subText: t('faq.sub.card.13')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.14'),
            subText: t('faq.sub.card.14')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.15'),
            subText: t('faq.sub.card.15')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.16'),
            subText: t('faq.sub.card.16')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.17'),
            subText: t('faq.sub.card.17')
          }, 
          {
            icon: IconOpen,
            text: t('faq.title.card.18'),
            subText: t('faq.sub.card.18')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.19'),
            subText: t('faq.sub.card.19')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.20'),
            subText: t('faq.sub.card.20')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.21'),
            subText: t('faq.sub.card.21')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.22'),
            subText: t('faq.sub.card.22')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.23'),
            subText: t('faq.sub.card.23')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.24'),
            subText: t('faq.sub.card.24')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.25'),
            subText: t('faq.sub.card.25')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.26'),
            subText: t('faq.sub.card.26')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.27'),
            subText: t('faq.sub.card.27')
          },
          {
            icon: IconOpen,
            text: t('faq.title.card.28'),
            subText: t('faq.sub.card.28')
          },

      ];


  return (

    <Container>
    <Line></Line>
    <Element name="section">
        <FadeIn className="fade-in-faq">
            <ControlCard>
                <Title>FAQ <span>.</span></Title>

                <ControllFaq>
                    {cardsData.map((card, index) => (
                        <div key={index}>
                            <ControlTexto onClick={() => toggleCard(index)}>
                                <Imagem src={openCard === index ? IconClose : card.icon} alt="Icone"  />
                                <Text>{card.text}</Text>
                            </ControlTexto>
                            {openCard === index && (
                                <SubText>
                                    {card.subText}
                                </SubText>
                            )}
                        </div>
                    ))}
                </ControllFaq>
            </ControlCard>
        </FadeIn>
    </Element>
</Container>

  );
}

export default Faq;
